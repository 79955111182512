<template>
  <div class="lottery">
    <div id="section-content" class="container container-fluid">
      <b-overlay id="overlay-background" :show="getLotteryAllList.length == 0" rounded="lg"
          variant="dark"
          opacity="0.35"
          blur="2px">
          <div class="container" >
      <div class="row m-0" >
          <template >
            <div class="col-4 col-sm-4 col-md-4 col-lg-3 p-1"   v-for="(item,j) in (getLotteryAllList.filter(v => (v.isOpen === '1')).sort(function (a, b) {return  isATimeOver(a.lottoCloseTime, b.lottoCloseTime) ? -1 : 1 })) " :key="'A'+j">
              <router-link v-bind:to="`/lotto/${item.lottoType}`" >
                <div class="huay-list-item2" >
                    <div class="row m-0 py-0 ">
                      <div class="col-4 p-0">
                        <img :src="require('@/assets/flags/'+item.lottoImage)" class="jss86 -img img_left pt-2" alt="Lotmatic แทงหวยออนไลน์" width="40" >
                      </div>
                      <div class="col-8 p-1">
                        <div class="-item-content-wrapper text-center">
                          <strong class="-text2 t_color2 f_08">{{ item.titleLottoCatagory }}</strong>
                          <div class="">
                            <div class="x-countdown-timer-wrapper -timer css-dtlfy1 t_time2">
                              <i class="far fa-clock -ic"></i>
                              <span data-finaldate="1610871600000" class="-time2 pl-1 countdown f_08" v-html="calTime(item.lottoCloseTime)"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                </div>
              </router-link>
            </div>
            </template>
            <template  >
              <div class="col-4 col-sm-4 col-md-4 col-lg-3 p-1" v-for="(item,j) in getLotteryAllList.filter(v => v.isOpen !== '1')" :key="'B'+j" >
                <router-link v-bind:to="`/lotto/${item.lottoType}`">
                  <div class="huay-list-item2">
                    <div class="">
                      <div class="row m-0 py-0 ">
                        <div class="col-4 p-0">
                          <img :src="require('@/assets/flags/'+item.lottoImage)" class="jss86 -img img_left pt-2" alt="Lotmatic แทงหวยออนไลน์" width="40" >
                        </div>
                        <div class="col-8 p-1">
                          <div class="-item-content-wrapper text-center">
                            <strong class="-text2 t_color2 f_08">{{ item.titleLottoCatagory }}</strong>
                            <div class="">
                              <div class="x-countdown-timer-wrapper -timer css-dtlfy1 t_time_off f_08">
                                <span>ปิดรับแทง</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </template>
          </div>
      </div>
    </b-overlay>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment'
import { dummyList } from '@/constants'
import {
  getClassIcons,
  getClassBackgroundIcons
} from '@/utils'
export default {
  data () {
    return {
      dummyList: dummyList,
      currentDate: '',
      show: true
    }
  },
  async created () {
    if (this.isGamePermission === 2) {

    }
    this.setLoading(true)
    setInterval(() => {
      this.getNow()
    }, 1000)
    await this.getLottoAll()
    this.setLoading(false)
  },
  methods: {
    ...mapActions(['getLottery', 'setLoading', 'getLottoAll', 'getYeekiCatalog']),
    getIconClass (title) {
      if (title) {
        return getClassIcons(title)
      } else {
        return ''
      }
    },
    getBackgroundColor (title) {
      if (title) {
        const classes = getClassBackgroundIcons(title)
        return classes
      } else {
        return ''
      }
    },
    calTime (value) {
      const final = moment(value)
      const now = this.currentDate ? this.currentDate : ''
      const timeleft = final - now
      if (timeleft > 0) {
        const days = Math.floor(timeleft / (1000 * 60 * 60 * 24))
        let hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        let minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60))
        if (hours < 10) {
          hours = '0' + hours
        }
        if (minutes < 10) {
          minutes = '0' + minutes
        }
        let seconds = Math.floor((timeleft % (1000 * 60)) / 1000)
        if (seconds < 10) {
          seconds = '0' + seconds
        }
        if (days > 1000) {
          return ''
        } else if (days > 0) {
          return `${days}<small>วัน</small>`
        } else {
          return `${hours}<small>:</small>${minutes}<small>:</small>${seconds}`
        }
      } else {
        return '00<small>:</small>00<small>:</small>00'
      }
    },
    getIsClose (value) {
      const final = moment(value)
      const now = this.currentDate ? this.currentDate : ''
      const timeleft = final - now
      if (timeleft > 0) {
        return false
      } else {
        return true
      }
    },
    getTime (time) {
      const timer = moment(time)
      const min = timer.minutes().toString()
      const hour = timer.hours().toString()
      const pad = '00'
      const minCal = pad.substring(0, pad.length - min.length) + min
      const hourCal = pad.substring(0, pad.length - hour.length) + hour

      return `${hourCal}: ${minCal}`
    },
    getNow () {
      this.currentDate = moment()
    },
    getTimeStamp (time) {
      return moment(time)
    },
    isATimeOver (a, b) {
      return moment(a) < moment(b)
    }
  },
  computed: {
    ...mapGetters(['getLotteryAllList', 'getYeekiCatalogList']),
    ...mapState({
      isGamePermission: (state) => state.authModule.isGamePermission
    })
  }
}
</script>
