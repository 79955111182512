<template>
  <div class="row m-0 home p-1">
    <div class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0" >
      <div class="container p-0">
        <div class="mx-1 my-2 box-new-top">
          <b-carousel id="carousel-1" :interval="4000" controls indicators background="#ababab" img-width="1082"
            img-height="338" class="lightSweep" style="text-shadow: 1px 1px 2px #333;border-radius: 8px !important;">
            <template>
              <b-carousel-slide :img-src="require('@/assets/huay/banner2.jpeg')">
              </b-carousel-slide>
              <b-carousel-slide :img-src="require('@/assets/huay/banner3.jpeg')">
              </b-carousel-slide>
              <b-carousel-slide :img-src="require('@/assets/huay/banner1.jpeg')">
              </b-carousel-slide>
            </template>
          </b-carousel>
        </div>
        <div class="row m-0">
          <div class="col-md-4 p-1"  :class="(dummyList.getYeekiCatalogList.length == index + 1) ? `col-12` : `col-6`"  v-for="(item,index) in dummyList.getYeekiCatalogList" :key="index">
            <router-link  v-bind:to="`/lotto/`+item.subCatID">
              <div  :class="item.bgClass">
              <div class="row m-0 p-1 huay-list-item">
                <div class="col-4 p-0 px-0">
                  <img  :src="require('@/assets/huay/'+item.img)" class="jss86 -img" alt="Lotmatic แทงหวยออนไลน์" width="50" >
                </div>
                <div class="col-8 px-0">
                  <div class="-item-content-wrapper">
                    <strong class="-text t_color">{{ item.subCatName }}</strong>
                    <div class=" -active MuiBox-root css-btmm89">
                      <div class="x-countdown-timer-wrapper -timer css-dtlfy1 t_time"><i class="far fa-clock -ic"></i><span class="-time pl-1" v-html="getCountDownRound(item.subCatID, index)"></span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </router-link>
          </div>
        </div>
        <div id="section-content" class="container container-fluid">
          <div class="p-0 w-100 bg-light main-content align-self-stretch">
            <div class=" bgwhitealpha text-secondary rounded p-0 xtarget col-lotto">
                <app-lotto />
                <app-result />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lottoPage from '@/pages/huayPage/content/lotto.vue'
import resultPage from '@/pages/huayPage/content/resultPage.vue'
import { mapGetters } from 'vuex'
import { dummyList } from '@/constants'
import moment from 'moment'
export default {
  async created () {
    setInterval(() => {
      this.getNow()
    }, 1000)
  },
  components: {
    'app-lotto': lottoPage,
    'app-result': resultPage
  },
  data () {
    return {
      dummyList: dummyList,
      currentDate: moment()
    }
  },
  computed: {
    ...mapGetters(['getLottoResultAllList'])
  },
  methods: {
    getNow () {
      this.currentDate = moment()
    },
    getIsCloseYeekee (currentX, min) {
      const now = this.currentDate !== null ? this.currentDate : ''
      const current = moment(currentX).add(min, 'minutes')
      const final = moment(current).diff(now)
      if (final > 0) {
        return false
      } else {
        return true
      }
    },
    calTime (value) {
      const now = this.currentDate ? this.currentDate : ''
      const final = moment(value).diff(now)
      if (final > 0) {
        const hours = Math.floor(
          (final % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        let minutes = Math.floor((final % (1000 * 60 * 60)) / (1000 * 60))
        if (minutes < 10) {
          minutes = '0' + minutes
        }
        let seconds = Math.floor((final % (1000 * 60)) / 1000)
        if (seconds < 10) {
          seconds = '0' + seconds
        }
        if (hours > 0) {
          return `${hours} ชั่วโมง`
        }
        return `</small>${minutes}<small>:</small>${seconds}`
      } else {
        return '00<small>:</small>00<small>:</small>00'
      }
    },
    getCountDownRound (subCatID, index) {
      let listTmp = []
      if (this.getLottoResultAllList[1][index].length > 0) {
        listTmp = this.getLottoResultAllList[1][index].filter((e) => !this.getIsCloseYeekee(e.date_close, 0))
      }
      if (listTmp.length > 0) {
        return this.calTime(listTmp[0].date_close)
      }
      return ''
    }
  }
}
</script>

<style>
</style>
