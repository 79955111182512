<template>
  <div class="result">
    <div id="section-content" class="container container-fluid">
      <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 140px) - 50px)">
        <div class="-heading-wrapper MuiBox-root css-0"><img
              src="https://app.lotmatic.co/build/web/img/effect-ranking-1.gif" class="jss58 -img"
              alt="Lotmatic แทงหวยออนไลน์" width="100" height="100">
            <h1 class="-title">ผลหวยล่าสุด</h1>
            <router-link class="-btn-latest-result css-1r1wxjc"  v-bind:to="`/member/result/all`">ดูหน้าผลหวยย้อนหลัง &gt;&gt;</router-link>
          </div>
        <b-overlay id="overlay-background" :show="showOverlay" rounded="lg" variant="dark" opacity="0.35" blur="2px">
          <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 xtarget col-lotto mb-5">
            <section id="contentbox">
              <app-hot-hit v-bind:dataList="getLottoResultAllList[3]" v-if="getLottoResultAllList[3].length > 0" />
              <app-country-lotto v-bind:info="getLottoResultAllList[0].filter(v => v.title == 'หวยรัฐบาล')[0]"
                v-if="getLottoResultAllList[0].filter(v => v.title == 'หวยรัฐบาล').length > 0" />
              <app-thai-lotto v-bind:toDay="toDay(selectDate)"
                v-bind:dataList="getLottoResultAllList[0].filter(v => v.title != 'หวยรัฐบาล')"
                v-if="getLottoResultAllList[0].filter(v => v.title != 'หวยรัฐบาล').length > 0" />
              <app-inter-lotto v-bind:toDay="toDay(selectDate)" v-bind:isMobile="$isMobile(selectDate)"
                v-bind:dataList="getLottoResultAllList[2]" v-if="getLottoResultAllList[2].length > 0" />
              <template v-if="getLottoResultAllList[1][0] != null">
                <app-yeekee-vip :toDay="toDay(selectDate)" :dataList="getLottoResultAllList[1][0]" :u8Type="0"
                  :isVip="false" v-if="getLottoResultAllList[1][0].length > 0" />
              </template>
              <template v-if="getLottoResultAllList[1][1] != null">
                <app-yeekee-vip :toDay="toDay(selectDate)" :dataList="getLottoResultAllList[1][1]" :u8Type="1"
                  :isVip="true" v-if="getLottoResultAllList[1][1].length > 0" />
              </template>
              <template v-if="getLottoResultAllList[1][2] != null">
                <app-yeekee-vip :toDay="toDay(selectDate)" :dataList="getLottoResultAllList[1][2]" :u8Type="2"
                  :isVip="true" v-if="getLottoResultAllList[1][2].length > 0" />
              </template>
            </section>
          </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>
<script>
import HotHit from '@/components/HotHit'
import CountryLotto from '@/components/CountryLotto'
import InterLotto from '@/components/InterLotto'
import ThaiLotto from '@/components/ThaiLotto'
import YeekeeVip from '@/components/YeekeeVip'
import { format } from 'date-fns'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    'app-hot-hit': HotHit,
    'app-country-lotto': CountryLotto,
    'app-inter-lotto': InterLotto,
    'app-yeekee-vip': YeekeeVip,
    'app-thai-lotto': ThaiLotto
  },
  async created () {
    try {
      this.showOverlay = true
      this.setLoading(true)
      await Promise.all([
        this.getLottoResultAll(this.selectDate)
      ])
      this.setLoading(false)
      this.showOverlay = false
    } catch (error) {
      this.setLoading(false)
      this.showOverlay = false
    }
  },
  methods: {
    ...mapActions(['setLoading', 'getLottoResultAll']),
    toDay: (data) => {
      if (data === '') {
        return format(new Date(), 'dd MMMM yyyy')
      } else {
        return format(new Date(data), 'dd MMMM yyyy')
      }
    }
  },
  computed: {
    ...mapGetters(['getLottoResultAllList'])
  },
  data: () => {
    return {
      selectDate: '',
      showOverlay: true
    }
  },
  watch: {
    selectDate: function (val) {
      this.showOverlay = true
      this.getLottoResultAll(val)
    },
    getLottoResultAllList: function (val) {
      if (this.showOverlay === true) {
        this.showOverlay = false
      }
    }
  }
}
</script>

<style>
.-heading-wrapper {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    position: relative;
    padding: 6px 16px 6px 64px;
    font-size: 0.9375rem;
    color: rgb(255, 255, 255);
    /* background: linear-gradient(90deg, rgb(234, 85, 69) 0px, rgb(249, 174, 84) 100%) center center / cover; */
    background: linear-gradient(90deg, #2851ff -0.7%, #50b4ff 113.23%);
    border-radius: 8px;
}
.-heading-wrapper .-img {
    position: absolute;
    bottom: -26px;
    left: -10px;
    width: 80px;
}
.-heading-wrapper .-title {
    margin: 0px;
    font-size: 1.5em !important;
}

.css-1r1wxjc {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-weight: 500;
    font-size: 1rem;
    padding: 6px 8px;
    border-radius: 4px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(38, 111, 212);
    min-width: auto;
    line-height: 1.5;
    text-transform: none;
}
.-btn-latest-result {
    padding: 6px 0px;
    color: rgb(255, 255, 255);
    font-size: 0.9375em;
    transition: all 0.2s ease 0s;
}
</style>
